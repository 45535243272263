import React, { Component } from "react";
import { connect } from "react-redux";
import { StoragesSystem } from "../../../@main-module/actions-manager/actions-data";
import { withRouter } from "../../../@hoc/with-router";
import { RenderNodes } from "../../../@render-node";
import dataBody from '../json/body.json'
import dataSections from '../json/sections.json'
import dataComments from '../json/comments.json'
import fonts from '../json/font.json'
import galery from '../json/galery.json'
import { FontHeader } from "../../../@font-header";
import '../scss/style.scss'
import { NavigationBar } from "../../../@nodes/navigation-bar";
import Share from "./share";

const PageDefaultProps = {
  StoragesSystem: {
    catalog: []
  }
};

const FILTERS_KEY = {
  catalog: ["navbar","header","stats","feature","theme","pricing","testimony","cta","faq","footer"],
  feature: ["navbar","feature","faq","footer"],
  theme: ["navbar","theme","faq","footer"],
  price: ["navbar","pricing","faq","footer"],
  portfolio: ["navbar","stats", "testimony","cta","faq","footer"],
}

class RenderPage extends Component {
  static defaultProps = PageDefaultProps;
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  timeOut = null;

  componentDidUpdate = (prevProps) => {
    
  }

  componentDidMount = () => {
    document.querySelector('body').className = dataBody.className
  }

  getRenderData = (sections, filters = []) => {
    if(filters.length > 0){
      const filtersObj = {};
      for (let i = 0; i < filters.length; i++) {
        filtersObj[filters[i]] = filters[i]
      }
      for (const key in sections) {
        const item = sections[key];
        const sectionKey = item?.media?.["fox-section"];
        if(filtersObj[sectionKey]){
          // data.sections[key].node = []
        }else{
          item.node = []
        }
      }
      return sections
    }
    return sections
  }
  getSectionFilters = () => {
    const key = this.props.searchParams?.get?.("pages");
    const filters = FILTERS_KEY[key] || [];
    return filters
  }

  isRenderShare = () => {
   return  this.props.searchParams?.get?.("share") != null ? true: false;
  }
  render() {
    const { props, state } = this;

    let filters  = this.getSectionFilters();
    let renderSections  = this.getRenderData(dataSections, filters);
    if(this.isRenderShare() ){
      return <Share {...props} />
    }
    return (
      <>
        {fonts.length > 0 && 
          <FontHeader fonts = {fonts} /> 
        }
        <RenderNodes {...props}  dataComments = {[]} isUseMusic = {true} isUseNavigation = {true} song = {"./images/song.mp3"} databaseId = {"1lc2iOkhu8r7iukqJa43xId_abifdN5mtJUnGpygkd_w"} data = {dataBody} sections = {renderSections} galery = {galery}/>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  StoragesSystem: state.StoragesSystem,
});
const mapDispatchToProps = (dispatch) => ({
  setStorage: (data = { data: null, type: 'yourkey' }) =>
    dispatch(new StoragesSystem().setStorage(data)),
});

export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(RenderPage, {
    isRestriected: true,
}));
