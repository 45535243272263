import React from "react";
import "./@assets/sass/main.scss";
import { MainApps } from "./@main-module/module-main";
import HomePage from './@app/client/home'
// import SharePage from './@app/client/share'


const dataRouteMap = [
  
  // {
  //   Component: SharePage,
  //   caseSensitive: false,
  //   path: "/share",
    
  // },
  {
    Component: HomePage,
    caseSensitive: false,
    path: "/*",
  },

];

export class Apps extends React.Component {
  render() {
    return <MainApps dataRouteMap={dataRouteMap} />;
  }
}

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Apps />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

